.week {
    display: inline-block;
    height: 4px;
    width: 4px;
    border-radius: 0.5px;
    margin: 0 1px;
    background-color: rgb(188, 188, 255);
}

.is-year-over {
    background-color: #030050;
}


.middle-week {
    margin-right: 10px;
}

.active-week-of-current-year {
    background-color: #030050;
}

@media (min-width: 768px) {
    .week {
        height: 10px;
        width: 10px;
        border-radius: 2px;
        margin: 0 2px;
    }

    .middle-week {
        margin-right: 10px;
    }   
}