.card {
    width: 85%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.11);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4.9px);
    -webkit-backdrop-filter: blur(4.9px);
    border: 1px solid rgba(255, 255, 255, 0.23);
    
    .form {
        .input {
            width: 100%;
        }
    }
}

@media (min-width: 768px) {
    .card {
        width: 600px;
        padding: 10px;
    }
}


