.year {
    display: inline-block;
}

.year-no {
    font-size: xx-small;
}

.decade-end-year {
    margin-top: 10px;
}

@media (min-width: 768px) {
    .year-no {
        font-size: small;
    }
}